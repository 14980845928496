/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
import React, { createContext } from "react"
import { gallery } from "./gallery"
import "./output.css"
import { quickCallButton } from "./quickCallButton"
import { roomCard } from "./roomCard"
import { termAndCondition } from "./termAndCondition"
import { text } from "./text"
import { contact } from "./contact"
import YouTube from "@mui/icons-material/YouTube"
export const lineLink = "https://lin.ee/CX19xsM"
export interface textLang {
  th: string
  en: string
}
export const telIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
)
const mapIcon = (
  <svg
    style={{ marginTop: "-30px", marginLeft: "30px" }}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
    />
  </svg>
)
interface MyState {
  lang: "TH" | "EN"
}
export const LangContext = createContext("EN")
interface MyProps {}
class App extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props)
    this.state = {
      lang: "EN",
    }
  }
  render() {
    const changeLangButton = () => {
      const getBG = (lang: "TH" | "EN") => {
        if (this.state.lang === lang) return "bg-soft-primary text-white"
      }

      return (
        <div className="absolute right-0 text-lg text-white">
          <button
            className={`rounded-b-2xl px-3 ${getBG("TH")}`}
            onClick={() => this.setState({ lang: "TH" })}
          >
            TH
          </button>
          <button
            className={`rounded-b-2xl px-3 ${getBG("EN")}`}
            onClick={() => this.setState({ lang: "EN" })}
          >
            EN
          </button>
        </div>
      )
    }
    return (
      <div className="bg-secondary ">
        <LangContext.Provider value={this.state.lang}>
          <div className=" font-poppins  container mx-auto shadow-2xl bg-cover ">
            {quickCallButton()}
            {changeLangButton()}
            <div className="">
              <div
                className={`bg-primary 
                lg:h-[70vh] sm:h-[70vh] h-[50vh] 
                flex items-center justify-center 
                lg:bg-bottom bg-bottom 
                bg-contain lg:bg-cover 
                bg-fixed
                bg-no-repeat
                `}
                style={{
                  backgroundImage: "url('./images/lightroom-1/DSC_0330.jpg')",
                  // backgroundImage: "url('./white-logo.png')",
                }}
              >
                <div
                  className="text-center m-auto "
                  // style={{ opacity: "0.0" }}
                >
                  <img src="white-logo.png" className="m-auto w-2/3 lg:w-1/3" />
                </div>
              </div>
            </div>

            <div className="container  mx-auto text-center border-spacing-4 ">
              <h1 className="text-3xl  text-white p-6 bg-soft-primary">
                {text({
                  en: "In the middle of Nan city",
                  th: "อยู่ใจกลางเมืองน่าน",
                })}
              </h1>
            </div>

            <div className="grid sm:grid-cols-2 bg-primary">
              <div>
                <img src="images/DSC_0303 Large.jpeg" className="bg-cover" />
              </div>
              <div>
                <div className="text-white p-10 lg:p-20 text-justify    ">
                  {text({
                    th: "ยินดีต้อนรับสู่โรงแรมน่านละมุน สถานที่ที่มีความเรียบง่าย ตั้งอยู่ใจกลางเมืองน่าน ล้อมรอบด้วยเฉดสีเขียวของน่านที่มีชีวิตชีวา สร้างบรรยากาศที่เติมเต็มการพักผ่อนของคุณได้อย่างลงตัว โรงแรมของเราตั้งอยู่ใกล้ถนนคนเดินใจกลางเมืองน่าน",
                    en: "Welcome to Nan Lamoon Hotel, a place that simplicity . Located in the heart of Nan City, surrounded by the vibrant hues of Nan green, it creates an atmosphere that perfectly complements your relaxation. Our hotel is conveniently near the walking street, right in the center of Nan City",
                  })}
                </div>
              </div>
            </div>

            {/* <div
              className={`bg-primary 
                lg:h-[70vh] sm:h-[50vh] h-[50vh] 
                flex items-center justify-center 
                lg:bg-bottom bg-bottom 
                bg-cover lg:bg-contain 
                bg-fixed
                bg-no-repeat
                `}
              style={{
                backgroundImage: "url('./images/IMG_7555-2 Large.jpeg')",
                // backgroundImage: "url('./white-logo.png')",
              }}
            >
              <div className="text-center m-auto text-white text-6xl"></div>
            </div> */}

            <div className="bg-secondary  p-5  w-full">
              <div className="text-3xl text-center text-primary p-6">
                {text({ th: "ห้องพัก", en: "Our Room" })}{" "}
              </div>
              <div className="flex flex-wrap text-center ">
                {roomCard({
                  roomTypeName: {
                    en: "Superior Double Bed",
                    th: "ซุพีเรียร์ เตียงเดี่ยว",
                  },
                  price: 2100,
                  image: "images/lightroom-1/DSC_0348.jpg",
                  description: {
                    th: `เตียงขนาด 6 ฟุค 1 เตียง
            ขนาดห้อง 35 ตรม.`,
                    en: `One 6 foot bed, room size 35 sqm.`,
                  },
                  videoLink: "https://www.facebook.com/reel/1092046842169375",
                })}
                {roomCard({
                  roomTypeName: {
                    en: "Superior Twin Bed",
                    th: "ซุพีเรียร์ ครอบครัว",
                  },
                  price: 2100,
                  image: "images/lightroom-1/DSC_0349.jpg",
                  description: {
                    th: `เตียงขนาด 3.5 ฟุค 2 เตียง
            ขนาดห้อง 35 ตรม.`,
                    en: `Two 3.5 foot beds, room size 35 sqm.`,
                  },
                  videoLink: "https://www.facebook.com/reel/3642248479340832",
                })}

                {roomCard({
                  roomTypeName: {
                    en: "Superior Triple Bed",
                    th: "ซุพีเรียร์ ครอบครัว",
                  },
                  price: 2500,
                  image: "images/lightroom-1/DSC_0350.jpg",
                  description: {
                    th: `เตียงขนาด 6 และ 3.5 ฟุค อย่างละ 1 เตียง
            ขนาดห้อง 35 ตรม.`,
                    en: `1 bed each size 6 and 3.5 foot, room size 35 sqm.`,
                  },
                  videoLink: "https://www.facebook.com/reel/1011335643418022",
                })}
              </div>
            </div>

            <div className="grid sm:grid-cols-2 bg-soft-primary">
              <div>
                <div className="text-white text-3xl   lg:text-5xl  ">
                  <div className="flex  items-center justify-center  relative ">
                    <div className="absolute text-center whitespace-pre  z-10">
                      {text({
                        th: "ที่จอดรถเพียงพอ\nสำหรับทุกห้อง",
                        en: "Convenient Parking",
                      })}
                      <br />
                      <button className="  border-soft-primary text-4xl  focus:bg-softer-primary ">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/reel/336744589078202"
                          className="hover:text-softer-primary"
                        >
                          <YouTube style={{ zoom: "250%" }} />
                        </a>
                      </button>
                    </div>

                    <div className="relative z-0 ">
                      <img
                        style={{
                          opacity: 0,
                          width: "100%",
                          zIndex: -1,
                        }}
                        src="images/IMG_7555-2 Large.jpeg"
                        className="bg-cover "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img src="images/IMG_7555-2 Large.jpeg" className="bg-cover" />
              </div>
            </div>

            {termAndCondition()}

            {contact()}
            <div className="bg-primary text-white ">
              <div className="text-3xl text-center p-6">
                {text({ en: "Map", th: "แผนที่" })}
              </div>
              <div className=" text-center">
                <div className=" bg-white min-w-full ">
                  <img src="map2.png" />
                  <div className="text-center text-primary pb-4 text-lg">
                    {text({
                      en: "10/8 Nai Wiang, Mueang Nan District, Nan 55000",
                      th: "10/8 ตำบลในเวียง อำเภอเมือง จังหวัดน่าน 55000",
                    })}
                  </div>
                </div>
                <button className="bg-soft-primary p-12  rounded-xl w-full ">
                  <p className="inline align-middle p-2 m-auto">
                    <a
                      className="text-2xl"
                      target="_blank"
                      href="https://www.google.com/maps/place/%E0%B9%82%E0%B8%A3%E0%B8%87%E0%B9%81%E0%B8%A3%E0%B8%A1%E0%B8%99%E0%B9%88%E0%B8%B2%E0%B8%99%E0%B8%A5%E0%B8%B0%E0%B8%A1%E0%B8%B8%E0%B8%99/@18.7718215,100.7692255,17z/data=!3m1!4b1!4m9!3m8!1s0x31278f62de6122eb:0xac312e98aa871d03!5m2!4m1!1i2!8m2!3d18.7718215!4d100.7692255!16s%2Fg%2F11vkbtbprv?entry=ttu"
                    >
                      <img src="google-map.svg" className="h-32 m-auto" />
                      <br />
                      {text({
                        en: "Open in Google Map",
                        th: "เปิดใน Google Map",
                      })}
                    </a>
                  </p>
                </button>
              </div>
            </div>

            {gallery()}
          </div>
          {PageFooter()}
        </LangContext.Provider>
      </div>
    )
  }
}
export default App

export function PageFooter() {
  return (
    <div className="bg-primary  text-white text-center p-24 text-xl">
      <div>
        {text({
          en: "10/8 Nai Wiang, Mueang Nan District, Nan 55000",
          th: "10/8 ตำบลในเวียง อำเภอเมือง จังหวัดน่าน 55000",
        })}
      </div>
    </div>
  )
}

import YouTube from "@mui/icons-material/YouTube"
import { textLang } from "./App"
import { text } from "./text"
export function roomCard(data: {
  roomTypeName: textLang
  price: number
  image: string
  description: textLang
  videoLink: string
}) {
  return (
    <div className="text-white text-center m-auto p-6 ">
      <div className="shadow-2xl  hover:shadow-soft-primary max-w-sm  bg-soft-primary rounded-2xl overflow-hidden">
        <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
          <img className="rounded-t-lg" src={data.image} alt="" />
        </a>
        <div className="p-6  overflow-hidden">
          <h5 className="text-gray-900 text-3xl font-medium mb-2 ">
            {text(data.roomTypeName)}
          </h5>
          <div className="text-center">{text(data.description)}</div>
        </div>
        <div className="grid grid-rows-1 grid-flow-col gap-0 overflow-hidden">
          <p className="text-white bg-primary text-4xl p-4">
            {data.price.toLocaleString()} ฿
          </p>
          <button className=" bg-primary border-l-2 border-soft-primary text-4xl  focus:bg-softer-primary">
            <a
              target="_blank"
              href={data.videoLink}
              className="hover:text-softer-primary"
            >
              <YouTube style={{ zoom: "200%" }} />
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

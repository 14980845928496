import React from "react"
import { text } from "./text"

export function gallery() {
  const source: { url: string }[] = [
    // { url: "images/lightroom-1/DSC_0326 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0327 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0328 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0329 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0330 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0348 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0366 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0367 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0365 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0339 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0340 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0341 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0349 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0350 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0342 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0343 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0362 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0363 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0331 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0364 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0332 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0333 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0334 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0335 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0336 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0337 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0338 Medium.jpeg" },

    { url: "images/lightroom-1/DSC_0336 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0351 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0352 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0354 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0355 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0356 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0357 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0358 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0359 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0360 Medium.jpeg" },

    { url: "images/lightroom-1/DSC_0369 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0370 Medium.jpeg" },
    { url: "images/lightroom-1/DSC_0372 Medium.jpeg" },
  ]
  return (
    <div className="bg-secondary">
      <div className="text-3xl text-center p-6 bg-softer-primary text-white">
        {text({ en: "More Pictures", th: "รูปภาพเพิ่มเติม" })}
      </div>
      <div className="grid grid-cols- sm:grid-cols-3  gap-4 p-4">
        {source.map((row) => (
          <div className="p-0 m-0">
            <img className="rounded-sm" src={row.url} />
          </div>
        ))}
      </div>
    </div>
  )
}
